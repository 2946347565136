/* You can add global styles to this file, and also import other style files */



$enable-negative-margins: true;
@import 'ngx-toastr/toastr';
@import 'ng-select.scss';

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/remixicon/fonts/remixicon.css';
@import 'ngx-toastr/toastr-bs5-alert';

@import "node_modules/flag-icons/sass/variables";
@import "node_modules/flag-icons/sass/flag-icons-base";

@if variable-exists(flag-icons-included-countries) {
    @each $country in $flag-icons-included-countries {
        @include flag-icon($country);
    }
}

@else {
    @include flag-icon(de);
    @include flag-icon(gb);
}

.wrapper {
    padding-left: 0;
    padding-left: var(--cui-sidebar-occupy-start, 0);
    background-color: #ECF2F7 !important;
    transition: margin-right 0.3s linear;
    margin-right: 0px !important
}

.toast-container {
    z-index: 10001 !important;
}

.NgxEditor {
    background-color: transparent !important;
}

input[type="file"]::file-selector-button {
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: var(--bs-gray-300);
    transition: 1s;
    border: none
}


input[type="file"]::file-selector-button:hover {
    border: none
}

input[type="file"] {
    border: 1px solid var(--bs-gray-300);
    border-top-left-radius: 3.25px;
    border-bottom-left-radius: 3.25px;
}

.cursor-pointer {
    cursor: pointer;
}